import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "root-component"
    }}>{`Root Component`}</h1>
    <p>{`rework provides a convenient way to run code on all pages: The root component.`}</p>
    <p>{`This component is one of the top-most one. By default, this is the component whose file path is `}<inlineCode parentName="p">{`src/components/App`}</inlineCode>{` (if any),
this can be changed `}<a parentName="p" {...{
        "href": "configuration.md#entry-react"
      }}>{`in the configuration`}</a>{` under the `}<inlineCode parentName="p">{`entry-react`}</inlineCode>{` property.`}</p>
    <h2 {...{
      "id": "usages"
    }}>{`Usages`}</h2>
    <p>{`You can use this component for a multitude of things such as:`}</p>
    <ul>
      <li parentName="ul">{`setting a common `}<a parentName="li" {...{
          "href": "../6-page-head.md"
        }}><inlineCode parentName="a">{`Helmet`}</inlineCode></a>{`,`}</li>
      <li parentName="ul">{`app-wide providers,`}</li>
      <li parentName="ul">{`adding an app-wide `}<inlineCode parentName="li">{`componentDidCatch()`}</inlineCode>{`,`}</li>
      <li parentName="ul">{`prevent rendering of the whole app until some resources have loaded (by not rendering the `}<inlineCode parentName="li">{`children`}</inlineCode>{` prop).`}</li>
    </ul>
    <p><strong parentName="p">{`Note`}</strong>{`: This component will receive the rest of the app tree under the `}<inlineCode parentName="p">{`children`}</inlineCode>{` prop. This prop must
be rendered or the rest of the app will not render.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import * as React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  children: React.ReactNode,
};

export default function App(props: Props) {
  // the \`children\` prop contains the rest of the component tree of the app.
  return (
    <>
      <Helmet defaultTitle="My Site" titleTemplate="My Site - %s" />
      {props.children}
    </>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      